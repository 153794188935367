/* eslint-disable import/no-unresolved */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { graphql, navigate } from 'gatsby';
import { useLocation } from '@reach/router';

// Lib UI components
import { Container, Row, Col, Visible } from 'react-grid-system';

// Local UI components

import Seo from '../shared/Seo';
import ImageTextOverlay from '../shared/UIKit/ImageTextOverlay';
import RegisterSection from '../page-components/Register/RegisterSection';

// Helpers & Utils
import { isLoggedIn, connexionRoutes } from '../shared/Services/auth';

// Style
import '../page-styles/register.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function Register() {
  /* ********************************** HOOKS ********************************* */
  /* ********************************** HOOKS ********************************* */
  const { pathname } = useLocation();
  const splitPathname = pathname.split('/').filter((value) => value.length > 0);
  const route = splitPathname[splitPathname.length - 1];

  function protectRoutes() {
    if (isLoggedIn()) {
      if (connexionRoutes.includes(route)) {
        navigate('/');
      }
    }
  }

  React.useEffect(() => {
    protectRoutes();
  }, []);

  /* ******************************** RENDERING ******************************* */
  return (
    <>
      <Seo title="Register" />
      <Container className="register" fluid>
        <Row>
          <Visible lg={5} xl={5} xxl={5}>
            <ImageTextOverlay />
          </Visible>
          <Col lg={7} md={12} sm={12} style={{ margin: '0 auto' }}>
            <RegisterSection />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["Register", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Register;
