/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';
// Assets
import ArrowDown from '../../../images/shared-images/arrow_select.png';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Select({
  inputId,
  placeholder,
  options,
  name,
  label,
  fieldErrorMessage,
  wasSubmitted,
  height,
  hasCustomFunction,
  addSelectedObject,
}) {
  /* ******************************** HOOKS ******************************* */
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(1);
  const [touched, setTouched] = useState(false);

  if (hasCustomFunction && selectedOption >= 0) {
    const object = { [name]: options[selectedOption] };
    addSelectedObject(object);
  }

  /* ******************************** CALLBACKS ******************************* */
  function getFieldError() {
    if (selectedOption < 0) {
      return fieldErrorMessage?.empty;
    }
    return null;
  }

  const errorMessage = getFieldError();
  const displayErrorMessage = (wasSubmitted || touched) && errorMessage;

  function toggleOptions() {
    setIsOptionsOpen(!isOptionsOpen);
  }

  const handleKeyDown = (index) => (e) => {
    if (e.key === ' ' || e.key === 'SpaceBar' || e.key === 'Enter') {
      e.preventDefault();
      setSelectedOption(index);
      setIsOptionsOpen(false);
    }
  };

  function handleBlur(e) {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsOptionsOpen(false);
      setTouched(true);
    }
  }

  /* ******************************** VARIABLES ******************************* */
  let buttonClassName = '';
  if (selectedOption === -1) {
    buttonClassName += 'default';
  }
  if (isOptionsOpen) {
    buttonClassName += ' expanded';
  }

  return (
    <div className="custom-select" onBlur={handleBlur}>
      <label htmlFor={inputId}>{label}</label>
      <div className="native-select">
        <select name={name} id={inputId} value={options[selectedOption]}>
          <option value="">{placeholder}</option>
          {options.map((option, index) => (
            <option key={option} value={options[index]}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <div className="wrapper">
        <div
          className="container"
          style={height !== undefined ? { height: `${height}px` } : {}}
          onBlur={handleBlur}
        >
          <button
            type="button"
            aria-haspopup="listbox"
            aria-expanded={isOptionsOpen}
            aria-labelledby={inputId}
            className={buttonClassName}
            onClick={toggleOptions}
          >
            {selectedOption < 0 ? placeholder : options[selectedOption]}
            <span className={`arrow ${isOptionsOpen ? 'rotate' : ''}`}>
              <img src={ArrowDown} alt="" aria-hidden="true" />
            </span>
          </button>
          <ul
            className={`options ${isOptionsOpen ? 'show' : ''}`}
            tabIndex={-1}
          >
            {options.map((option, index) => (
              <li
                id={option}
                key={option}
                role="option"
                aria-selected={selectedOption === index}
                tabIndex={0}
                onKeyDown={handleKeyDown(index)}
                onClick={() => {
                  setSelectedOption(index);
                  setIsOptionsOpen(false);
                }}
              >
                {option}
              </li>
            ))}
          </ul>
        </div>
        {displayErrorMessage ? (
          <span role="alert" id={`${name}-error`} className="error-message">
            {errorMessage}
          </span>
        ) : null}
      </div>
    </div>
  );
}

Select.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  height: PropTypes.number,
  wasSubmitted: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  fieldErrorMessage: PropTypes.objectOf(PropTypes.string, PropTypes.string),
  hasCustomFunction: PropTypes.bool,
  addSelectedObject: PropTypes.func,
  inputId: PropTypes.string.isRequired,
};

Select.defaultProps = {
  name: '',
  label: '',
  height: undefined,
  wasSubmitted: undefined,
  fieldErrorMessage: undefined,
  hasCustomFunction: false,
  addSelectedObject: () => {},
};

export default Select;
