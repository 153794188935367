import axios from 'axios';
import { navigate } from 'gatsby';
import { baseUrl } from '../APIs';
import { isBrowser } from '../Helpers/isBrowser';

export function getUser() {
  if (isBrowser() && window.localStorage.getItem('user')) {
    return JSON.parse(window.localStorage.getItem('user'));
  }
  return {};
}

export function getToken() {
  if (isBrowser() && window.localStorage.getItem('user')) {
    const { token } = JSON.parse(window.localStorage.getItem('user'));
    return token;
  }
  return undefined;
}

function setUser(user) {
  window.localStorage.setItem('user', JSON.stringify(user));
}

function redirectToHome() {
  navigate('/');
}

function getProfileDetails(token, callback, setIsLoading) {
  axios
    .get(`${baseUrl}/users/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      setUser({ ...response.data, token });
      callback();
    })
    .catch((e) => console.error(e))
    .finally(() => setIsLoading(false));
}

async function uploadProfilePicture(picture) {
  try {
    const file = new FormData();
    file.append('files', picture);

    let pictureId;
    await axios.post(`${baseUrl}/upload`, file).then((response) => {
      pictureId = response.data[0].id;
    });
    return pictureId;
  } catch (error) {
    throw new Error(error);
  }
}

export async function updateUser(
  token,
  fieldValues,
  setUpdate,
  hasImageChanged,
  setIsLoading,
) {
  try {
    setIsLoading(true);
    const body = fieldValues;
    const { profilePicture } = body;
    delete body.profilePicture;

    if (hasImageChanged) {
      body.profilePicture = await uploadProfilePicture(profilePicture);
    }

    await axios.put(
      `${baseUrl}/users-permissions/users/me`,
      { ...body },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    getProfileDetails(token, undefined, setIsLoading);
    setUpdate(true);
  } catch (error) {
    console.log(error);
  }
}

export function handleLogin(email, password, setErrorMessage, setIsLoading) {
  setIsLoading(true);
  axios
    .post(`${baseUrl}/auth/local/`, {
      identifier: email,
      password,
    })
    .then((response) => {
      getProfileDetails(response?.data?.jwt, redirectToHome, setIsLoading);
    })
    .catch((error) => {
      const errorMessage = error?.response?.data?.error?.message;
      const errors = [
        {
          message: 'Invalid identifier or password',
          name: 'invalide-credentiels',
        },
        {
          message: 'Your account has been blocked by an administrator',
          name: 'blocked',
        },
      ];
      errors.forEach(({ message, name }) => {
        if (message === errorMessage) {
          setErrorMessage(name);
        }
      });
      setIsLoading(false);
    });
}

export function handleRegister(
  fieldValues,
  setErrorMessage,
  isAuthorized,
  setIsLoading,
) {
  if (!isAuthorized) {
    setErrorMessage((errorMessage) => ({
      ...errorMessage,
      authorization:
        "Vous devez accepter les conditions d'utilisation pour continuer",
    }));
    return;
  }

  setIsLoading(true);
  const file = new FormData();
  file.append('files', fieldValues.profilePicture);

  axios
    .post(`${baseUrl}/upload`, file)
    .then((response) => {
      const pictureId = response.data[0].id;
      axios
        .post(`${baseUrl}/auth/local/register`, {
          ...fieldValues,
          username: fieldValues.email,
          profilePicture: pictureId,
        })
        .then(() => {
          navigate('/');
        })
        .catch((error) => {
          const errorMessage = error?.response?.data?.error?.message;
          if (errorMessage === 'Email or Username are already taken') {
            setErrorMessage('email-taken');
            return false;
          }
          if (errorMessage === 'password must be at least 6 characters') {
            setErrorMessage('short-password');
            return false;
          }

          setErrorMessage(undefined);
          return false;
        });
    })
    .catch(() => {
      return false;
    })
    .finally(() => setIsLoading(false));
}

export function isLoggedIn() {
  const user = getUser();
  return !!user.email;
}
export function logout(callback) {
  setUser({});
  callback();
}

export const protectedRoutes = [
  'profile',
  'favorites',
  'demande',
  'profiles',
  'profile-details',
];
export const connexionRoutes = ['register', 'login'];
