/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';

// UI lib comoponents
import { Col } from 'react-grid-system';

// Local UI components
import Image from '../../Image';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function ImageTextOverlay() {
  /* ******************************** CONSTANTS ******************************* */

  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */

  // State

  // Other hooks like reux or localization ...

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */
  /**
   * Computes the perimeter
   */

  /* ***************************** RENDER HELPERS ***************************** */

  /* ******************************** RENDERING ******************************* */

  return (
    <Col xxl={4} xl={5} lg={5} md={12} xs={12} className="image-text-overlay">
      <Image src="Frame.png" alt="elife" />
      <p>Recruit the best tech talents for your business</p>
    </Col>
  );
}

ImageTextOverlay.propTypes = {};

ImageTextOverlay.defaultProps = {};

export default ImageTextOverlay;
