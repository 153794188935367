/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import { Row, Col } from 'react-grid-system';
import Select from '../../../shared/UIKit/Select';
// UI lib comoponents

// Local UI components
import InputField from '../../../shared/UIKit/InputField';
import FormButton from '../../../shared/UIKit/FormButton';
import Loader from '../../../shared/UIKit/Loader';

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
import { handleRegister } from '../../../shared/Services/auth';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function RegisterSection() {
  /* ********************************** HOOKS ********************************* */
  const [errorMessage, setErrorMessage] = useState();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const {
    title,
    account,
    signin,
    name,
    function1,
    email,
    password,
    companyname,
    phone,
    location,
    logo,
    company,
    button,
    condition,
    selectedFields,
    errors,
  } = t('register', {
    returnObjects: true,
  });

  /* ***************************** CALLBACKS ***************************** */

  function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const fieldValues = Object.fromEntries(formData.entries());

    handleRegister(fieldValues, setErrorMessage, isAuthorized, setIsLoading);

    return true;
  }

  function getEmailIsTakenError() {
    if (errorMessage === 'email-taken') {
      return <div className="email-taken">{errors[errorMessage]}</div>;
    }
    return null;
  }

  function getPasswordError() {
    if (errorMessage === 'short-password') {
      return <div className="email-taken">{errors[errorMessage]}</div>;
    }
    return null;
  }

  /* ***************************** RENDER HELPERS ***************************** */

  /**
   * Renders the login Link
   * @returns {React.Element} The rendered footer
   */
  function GetLoginLink() {
    return (
      <Row justify="end">
        <div className="login-link">
          <p>{account}</p>
          <Link to="/login">{signin}</Link>
        </div>
      </Row>
    );
  }

  /* ******************************** RENDERING ******************************* */

  return (
    <Row className="register-section" align="evenly" justify="center">
      {isLoading ? <Loader fullScreen /> : null}
      <Col sm={12} lg={12}>
        <GetLoginLink />
      </Col>
      <Col>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <h2>{title}</h2>
            </Col>
          </Row>
          <Row>
            <Col className="adjust" lg={6} sm={12}>
              <InputField name="fullName" label={name} type="text" required />
            </Col>
            <Col
              lg={6}
              sm={12}
              style={{ marginBottom: '3rem', marginTop: '3rem' }}
            >
              <InputField
                name="function"
                label={function1}
                type="text"
                required
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: '3rem' }}>
            <Col>
              <InputField name="email" label={email} type="email" required />
              {getEmailIsTakenError()}
            </Col>
          </Row>
          <Row style={{ marginBottom: '3rem' }}>
            <Col>
              <InputField
                name="password"
                label={password}
                type="password"
                placeholder="6+ strong character"
                required
              />
            </Col>
            <Col sm={12} lg={12}>
              {getPasswordError()}
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={12} style={{ marginBottom: '3rem' }}>
              <InputField
                name="companyName"
                label={companyname}
                type="text"
                required
              />
            </Col>
            <Col lg={6} sm={12} style={{ marginBottom: '3rem' }}>
              <InputField
                name="phoneNumber"
                label={phone}
                type="tel"
                required
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputField
                name="location"
                label={location}
                type="text"
                required
              />
            </Col>
          </Row>
          <Row justify="center" align="center">
            {/* <Col className="adjust" lg={6} sm={12}>
              <select name="activityArea" defaultValue="" required>
                <option value="" disabled>{`${activityarea} `}</option>
                <option value="Informatique">Informatique</option>
                <option value="Commerce">Commerce</option>
              </select>
            </Col> */}
            <Col sm={12} lg={6}>
              <Select
                name={selectedFields[0]?.name}
                label={selectedFields[0]?.label}
                height={54}
                options={selectedFields[0]?.options}
                hasCustomFunction
                placeholder={selectedFields[0]?.placeholder}
                inputId={selectedFields[0]?.id}
              />
            </Col>
            <Col
              lg={6}
              sm={12}
              style={{ marginBottom: '3rem', marginTop: '3rem' }}
            >
              <input
                name="profilePicture"
                type="file"
                placeholder={logo}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <textarea name="companyDescription" required />
              <span>{company}</span>
            </Col>
          </Row>
          <Row
            className="form-confirmation"
            justify="between"
            align="center"
            style={{ marginBottom: '2rem', marginTop: '2rem' }}
          >
            <Col sm={12} lg={12} className="check-container">
              <input
                id="conditions-use"
                type="checkbox"
                className="check"
                checked={isAuthorized}
                onChange={() => setIsAuthorized(!isAuthorized)}
              />
              <label htmlFor="conditions-use">{condition}</label>
              {errorMessage?.authorization !== undefined && !isAuthorized ? (
                <div className="not-authorized">
                  {errorMessage.authorization}
                </div>
              ) : null}
            </Col>
            <Col sm={12} lg={12}>
              <Row justify="end" nogutter>
                <FormButton text={button} />
              </Row>
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  );
}

RegisterSection.propTypes = {};

RegisterSection.defaultProps = {};

export default RegisterSection;
